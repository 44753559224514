'use strict';

(function() {
  function Resource($resource) {
    return $resource('api/v1/resources/:resourceId/:controller/:page/:limit/:sortBy/:order', {
      id: '@_id'
    }, {
      addResource: {
        method: 'POST',
      },
      deleteResource: {
        method: 'DELETE',
        params: {
          controller: 'resource'
        }
      },
      filterResources: {
        method: 'POST',
        params: {
          controller: 'filter-data'
        }
      }
    });
  }

  angular.module('windmanagerApp')
    .factory('Resource', Resource);
})();
